<script setup>
import axios from 'axios';
import { inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const route = inject('route');

const props = defineProps({
    parentForm: Object,
});

const form = ref({
    postcode: '',
    street_number: '',
    street_number_addition: '',
    street_name: '',
    city: '',
    country: '',
    longitude: '',
    latitude: '',
    errors: [],
});
console.log(props.parentForm);
onMounted(() => {
    form.value = props.parentForm;
});

const emit = defineEmits(['addressInputChanged']);

const handleInputChange = (name, value) => {
    if (name !== 'postcode' && name !== 'street_number') {
        return;
    }

    emit('addressInputChanged', name, value);

    fillAddress();
};

const fillAddress = async () => {
    if (!form.value.postcode || !form.value.street_number) {
        return;
    }

    form.value.errors.postcode = [];
    await axios
        .get(
            route('api.v0.address-check.index', {
                postcode: form.value.postcode,
                street_number: form.value.street_number,
            })
        )
        .then(async function (response) {
            emit('addressInputChanged', {
                postcode: response.data.data.postcode,
                street_number: response.data.data.street_number,
                street_name: response.data.data.street_name,
                city: response.data.data.city,
                country: 'NL',
                longitude: response.data.data.longitude,
                latitude: response.data.data.latitude,
            });

            form.value.postcode = response.data.data.postcode;
            form.value.street_number = response.data.data.street_number;
            form.value.street_name = response.data.data.street_name;
            form.value.city = response.data.data.city;
            form.value.country = 'NL';
            form.value.longitude = response.data.data.longitude;
            form.value.latitude = response.data.data.latitude;
            form.value.errors.postcode = [];
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 404) {
                    form.value.errors.postcode = [
                        t('Was unable to find an address with this postcode and street number'),
                    ];
                }
            }
        });
};
</script>
<template>
    <div class="grid gap-5">
        <div class="grid gap-4 md:grid-cols-3">
            <FormKit
                type="text"
                name="postcode"
                :label="$t('Postal code')"
                v-model="form.postcode"
                :errors="form.errors.postcode"
                :value="form.postcode"
                validate="required"
                @input="handleInputChange('postcode', $event)"
            />
            <FormKit
                type="text"
                name="street_number"
                :label="$t('Street Number')"
                v-model="form.street_number"
                :errors="form.errors.street_number"
                :value="form.street_number"
                validate="required"
                @input="handleInputChange('street_number', $event)"
                :delay="500"
            />

            <FormKit
                type="text"
                name="street_number_addition"
                :label="$t('Street Number Addition')"
                v-model="form.street_number_addition"
                :errors="form.errors.street_number_addition"
                :value="form.street_number_addition"
                validate="required"
                @input="emit('addressInputChanged', 'street_number_addition', $event)"
                :delay="500"
            />
        </div>

        <div class="grid gap-4 md:grid-cols-2">
            <FormKit
                type="text"
                name="street_name"
                :label="$t('Street name')"
                v-model="form.street_name"
                :errors="form.errors.street_name"
                :value="form.street_name"
                validate="required"
                @input="emit('addressInputChanged', 'street_name', $event)"
            />

            <FormKit
                type="text"
                name="city"
                :label="$t('City')"
                v-model="form.city"
                :errors="form.errors.city"
                :value="form.city"
                validate="required"
                @input="emit('addressInputChanged', 'city', $event)"
            />
        </div>
        <div class="grid gap-4 md:grid-cols-3">
            <FormKit
                type="text"
                name="country"
                :label="$t('Country')"
                v-model="form.country"
                :errors="form.errors.country"
                :value="form.country"
                validate="required"
                @input="emit('addressInputChanged', 'country', $event)"
            />

            <FormKit
                type="text"
                name="longitude"
                :label="$t('Longitude')"
                v-model="form.longitude"
                :errors="form.errors.longitude"
                :value="form.longitude"
                validate="required"
                @input="emit('addressInputChanged', 'longitude', $event)"
            />

            <FormKit
                type="text"
                name="latitude"
                :label="$t('Latitude')"
                v-model="form.latitude"
                :errors="form.errors.latitude"
                :value="form.latitude"
                validate="required"
                @input="emit('addressInputChanged', 'latitude', $event)"
            />
        </div>
    </div>
</template>
